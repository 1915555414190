import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import WorkCard from '@/components/molecules/works/WorkCard';

const imageAdjust = css({
  height: '100%',
});

const decorationImageBox = (theme: Theme) => css({
  height: '30vh',
  backgroundColor: theme.palette.background.default,
});

const decorationImage = css({
  height: '100%',
  opacity: 0.6
});

const CivilEngineering: React.VFC = () => {
  return (
    <section id="civilEngineering">
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoTypeDisplay
            backgroundColor="primary"
            titleTextEn={['Civil', 'Engineering']}
            titleText="土木施工例"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="サイクリングコース整備工事"
            desc="富士見江川右岸サイクリングコース整備工事"
            color="light"
          >
            <StaticImage
              alt="civilEngineeringImage01"
              src="../../../images/civilEngineering/image01.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="採納道路整備工事"
            desc="富士見市採納道路整備工事"
            color="light"
          >
            <StaticImage
              alt="civilEngineeringImage02"
              src="../../../images/civilEngineering/image02.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="外構工事"
            desc="こども動物自然公園フォレストカフェ外構工事"
            color="light"
          >
            <StaticImage
              alt="civilEngineeringImage03"
              src="../../../images/civilEngineering/image03.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="スロープ 設置工事"
            desc="ふじみ野駅（西口・東口）スロープ設置工事"
            color="light"
          >
            <StaticImage
              alt="civilEngineeringImage04"
              src="../../../images/civilEngineering/image04.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="駐輪場設置工事"
            desc="埼玉県立川越工業高等学校　駐輪場設置工事"
            color="light"
          >
            <StaticImage
              alt="civilEngineeringImage05"
              src="../../../images/civilEngineering/image05.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="防球ネット設置工事"
            desc="市立みずほ台小学校防球ネット設置工事"
            color="light"
          >
            <StaticImage
              alt="civilEngineeringImage06"
              src="../../../images/civilEngineering/image06.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid
          item xs={12} sm={6} md={8} lg={3}
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
              md:'block',
              lg:'block'
            }
          }}
        >
          <Box css={decorationImageBox}>
            <StaticImage
              alt="inWorksRenovation01"
              src="../../../images/decoration/inWorksCivilEngineering01.jpg"
              layout="fullWidth"
              css={decorationImage}
            />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default CivilEngineering;
