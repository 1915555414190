import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import WorkCard from '@/components/molecules/works/WorkCard';

const imageAdjust = css({
  height: '100%',
});

const Architecture: React.VFC = () => {
  return (
    <section id="architecture">
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoTypeDisplay
            backgroundColor="secondary"
            titleTextEn={['Architecture']}
            titleText="建築施工例"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="ふじみ野市立さぎの森小学校体育館改修工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage01"
              src="../../../images/architecture/image01.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="公共施設新築工事"
            desc="ふじみ野市立大井子育て支援センター建設工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage02"
              src="../../../images/architecture/image02.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="RCマンション・事務所"
            desc="ホワイトロード新築工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage03"
              src="../../../images/architecture/image03.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="所沢航空記念公園格納庫及び発祥記念館改修工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage04"
              src="../../../images/architecture/image04.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="警察・消防施設"
            desc="西入間警察署　坂戸駅前交番新築工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage05"
              src="../../../images/architecture/image05.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="RCマンション・事務所"
            desc="第７富士見ビル新築工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage06"
              src="../../../images/architecture/image06.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="警察・消防施設"
            desc="富士見市消防団第８分団車庫新築工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage07"
              src="../../../images/architecture/image07.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="増築工事"
            desc="富士見市立関沢小学校増築工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage08"
              src="../../../images/architecture/image08.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="新築工事"
            desc="富士見市立中央公園交流施設建設工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage09"
              src="../../../images/architecture/image09.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="富士見市立南畑小学校大規模改修工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage10"
              src="../../../images/architecture/image10.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="刑事施設"
            desc="法務省　川越少年刑務所鍛錬所設置工事"
            color="dark"
          >
            <StaticImage
              alt="architectureImage11"
              src="../../../images/architecture/image11.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
      </Grid>
    </section>
  );
};

export default Architecture;
