import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const overviewBandBox = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default,
});

const overViewContents = css({
  padding: '1.5rem 0',
  color: '#ffffff',
  lineHeight: '1.8rem',
  width: '90%',
  margin: '0 auto'
});

const brAdjust = (theme: Theme) => css({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
});

const displaySwitch = (theme: Theme) => css({
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  }
});

const OverviewBand: React.VFC = () => {
  return (
    <Box css={[overviewBandBox, displaySwitch]}>
      <Typography align="center" css={overViewContents}>
        施工内容は、新築から改修、リフォームまで。
        <br css={brAdjust} />
        お客様は、個人から法人、自治体まで。
        <br />
        幅広く活動する私たちの実績をご紹介します。
      </Typography>
    </Box>
  );
};

export default OverviewBand;
