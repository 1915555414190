import React from 'react';

import { css, Theme } from '@emotion/react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

type WorkCardProps = {
  children: React.ReactNode;
  title: string;
  desc: string;
  color: 'light' | 'dark';
}

const cardStyles = (theme: Theme) => css({
  borderRadius: 0,
  boxShadow: 'none',
  [theme.breakpoints.up('sm')]: {
    height: '30vh',
  }
});

const lightBackground = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default
});

const darkBackground = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default
});

const cardMediaStyles = (theme: Theme) => css({
  [theme.breakpoints.up('sm')]: {
    height: '20vh',
  }
});

const cardTitleStyles = css({
  fontSize: '1.1rem',
  fontWeight: 600,
});

const darkText = css({
  color: '#ffffff'
});

const WorkCard: React.VFC<WorkCardProps> = (props) => {
  const { children, title, desc, color } = props;

  return (
    <Card
      css={[
        cardStyles,
        color === 'light' && lightBackground,
        color === 'dark' && darkBackground,
      ]}
      >
      <CardMedia css={cardMediaStyles}>
        { children }
      </CardMedia>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          css={[
            cardTitleStyles,
            color === 'dark' && darkText
          ]}
        >
          { title }
        </Typography>
        <Typography
          variant="body2"
          css={
            color === 'dark' && darkText
          }
        >
          { desc }
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WorkCard;
